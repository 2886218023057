import actions from './actions';

const {
  AXIOS_TABLERO_BEGIN,
  AXIOS_TABLERO_SUCCESS,
  AXIOS_TABLERO_ERR,
  AXIOS_TABLERORRHH_BEGIN,
  AXIOS_TABLERORRHH_SUCCESS,
  AXIOS_TABLERORRHH_ERR,
  AXIOS_TABLEROHABILITACIONES_BEGIN,
  AXIOS_TABLEROHABILITACIONES_SUCCESS,
  AXIOS_TABLEROHABILITACIONES_ERR,
} = actions;

const initialStateSingle = {
  nombre: '',
  nombrecompleto: '',
  legajo: 0,
  firma: 0,
  firmados: 0,
  pendientes: 0,
  notificaciones: 0,
  cumpleaños2: [],
  cumpleaños: 0,
  aniversario: 0,
  loading: false,
  error: null,
};

const initialStateSingleRRHH = {
  total: [],
  total2: [],
  morosos: [],
  activos: 0,
  pendientes: 0,
  loading: false,
  error: null,
};

const initialStateSingleHabilitaciones = {
  total: [],
  total2: [],
  loading: false,
  error: null,
};

const tableroReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case AXIOS_TABLERO_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };

    case AXIOS_TABLERO_SUCCESS:
      return {
        ...initialStateSingle,
        firma: data.firma,
        nombre: data.nombre,
        firmados: data.firmados,
        nombrecompleto: data.nombrecompleto,
        legajo: data.legajo,
        pendientes: data.pendientes,
        notificaciones: data.notificaciones,
        cumpleaños: data.cumpleaños,
        cumpleaños2: data.cumpleaños2,
        aniversario: data.aniversario,
        error: false,
        loading: false,
      };

    case AXIOS_TABLERO_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const tableroRRHHReducer = (state = initialStateSingleRRHH, action) => {
  const { type, data, err } = action;
  switch (type) {
    case AXIOS_TABLERORRHH_BEGIN:
      return {
        ...initialStateSingleRRHH,
        loading: true,
      };
    case AXIOS_TABLERORRHH_SUCCESS:
      return {
        ...initialStateSingleRRHH,
        total: data.total,
        total2: data.total2,
        morosos: data.morosos,
        activos: data.activos,
        pendientes: data.pendientes,
        error: false,
        loading: false,
      };

    case AXIOS_TABLERORRHH_ERR:
      return {
        ...initialStateSingleRRHH,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const tableroHabilitacionesReducer = (state = initialStateSingleHabilitaciones, action) => {
  const { type, data, err } = action;
  switch (type) {
    case AXIOS_TABLEROHABILITACIONES_BEGIN:
      return {
        ...initialStateSingleHabilitaciones,
        loading: true,
      };
    case AXIOS_TABLEROHABILITACIONES_SUCCESS:
      return {
        ...initialStateSingleHabilitaciones,
        total: data.total,
        total2: data.total2,
        error: false,
        loading: false,
      };

    case AXIOS_TABLEROHABILITACIONES_ERR:
      return {
        ...initialStateSingleHabilitaciones,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { tableroReducer, tableroRRHHReducer, tableroHabilitacionesReducer };
